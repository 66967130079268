import c0 from './accordion_controller';
import c1 from './autosize_controller';
import c2 from './file_upload_controller';
import c3 from './password_peek_controller';
import c4 from './swal_confirm_controller';
import c5 from './tabs_controller';
import c6 from './toggle_controller';
export const definitions = [
	{identifier: 'accordion', controllerConstructor: c0},
	{identifier: 'autosize', controllerConstructor: c1},
	{identifier: 'file-upload', controllerConstructor: c2},
	{identifier: 'password-peek', controllerConstructor: c3},
	{identifier: 'swal-confirm', controllerConstructor: c4},
	{identifier: 'tabs', controllerConstructor: c5},
	{identifier: 'toggle', controllerConstructor: c6},
];
