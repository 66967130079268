import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

   initialize() {

    console.log('draw!');
            var p1x =  parseFloat(this.data.get("originX"));
            var p1y = parseFloat(this.data.get("originY"));
            var p2x = parseFloat(this.data.get("destinationX"));
            var p2y = parseFloat(this.data.get("destinationY"));

            // mid-point of line:
            var mpx = (p2x + p1x) * 0.5;
            var mpy = (p2y + p1y) * 0.5;

            // angle of perpendicular to line:
            var theta = Math.atan2(p2y - p1y, p2x - p1x) - Math.PI / 2;

            // distance of control point origin mid-point of line:
            var offset = -100;

            // location of control point:
            var c1x = mpx + offset * Math.cos(theta);
            var c1y = mpy + offset * Math.sin(theta);

            // construct the command to draw a quadratic curve
            var curve = "M" + p1x + " " + p1y + " Q " + c1x + " " + c1y + " " + p2x + " " + p2y;
            // var curveElement = document.getElementById("curve-" + path);
            var path ='<path class="flight" d="'+curve+'" stroke="white" stroke-width="3" stroke-linecap="round" fill="transparent"></path>';
            document.querySelector("svg#map .marker.origin").insertAdjacentHTML('beforebegin', path);
            document.querySelector("#map-container").innerHTML = document.querySelector("#map-container").innerHTML;

  }

}