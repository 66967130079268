import c0 from './flight_controller';
import c1 from './modal_controller';
import c2 from './toggle_controller';
import c3 from './tooltip_controller';
export const definitions = [
	{identifier: 'flight', controllerConstructor: c0},
	{identifier: 'modal', controllerConstructor: c1},
	{identifier: 'toggle', controllerConstructor: c2},
	{identifier: 'tooltip', controllerConstructor: c3},
];
